import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, tap } from 'rxjs';
import { AppState } from '../../store/model/state.model';
import { App } from '../../types/main.types';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  isLoading = false;

  appState$: Observable<App>;

  subscriptionRef: Subscription;

  constructor(
    private router: Router,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.appState$ = this.store
      .select((store) => store.app)
      .pipe(
        tap((appState: App) => {
          this.isLoading = appState.loading;
        }),
      );
    this.subscriptionRef = this.appState$.subscribe();

    // MP-301 - Required for redirecting to shared nav link after new signup
    const navUID = localStorage.getItem('navUID') || '';
    const newUser = localStorage.getItem('newUser') || '';
    if (newUser === 'true' && navUID) {
      this.router.navigate(['nav', navUID]);
    }
    localStorage.removeItem('newUser');
    localStorage.removeItem('navUID');
  }
}

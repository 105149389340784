<div
  #timeLineContainer
  class="surface-ground container mx-auto flex h-full flex-col justify-between overflow-auto"
  role="main"
  *ngIf="!isLoading"
>
  <div class="grow-1 mx-auto h-auto w-full max-w-[720px] px-2 pb-6 pt-2">
    <div class="linear max mx-auto mb-24 transition-all duration-500">
      <p-card styleClass="padding-clear">
        <div class="grid grid-cols-1 gap-y-4 px-2 py-4 sm:grid-cols-6">
          <div class="col-span-6 md:col-span-6">
            <div class="grow-1 stretch-1 flex-start flex items-center">
              <div class="mr-4 flex">
                <span class="inline">
                  <div class="relative h-[40px] w-[40px]">
                    <p-avatar
                      styleClass="mr-2 h-[40px] w-[40px]"
                      shape="circle"
                      size="large"
                    >
                      <img
                        *ngIf="postView.creatorObj.profilePicUrl"
                        [src]="
                          getProfileImage(postView.creatorObj.id)
                            | image
                            | async
                        "
                        alt=""
                      />
                      <img
                        *ngIf="!postView.creatorObj.profilePicUrl"
                        src="../assets/images/user-pic-default.svg"
                        alt="default"
                      />
                    </p-avatar>
                  </div>
                </span>
              </div>
              <div class="grow">
                <div class="flex flex-col">
                  <div class="">
                    <span class="h-level-3">{{
                      postView.refPost ? postView.refPost.title : postView.title
                    }}</span>
                  </div>
                  <div class="">
                    <span
                      class="block min-w-0 max-w-full break-words text-left text-[0.8rem]"
                    >
                      {{ moment(postView.postedTime).fromNow() }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="">
                <button
                  pButton
                  class="mx-3 h-[32px] rounded-full p-1"
                  (click)="
                    onMenuToggle(
                      $event,
                      postView.type === 'HUDDLE'
                        ? 'post_huddle_view'
                        : postView.type === 'COMMUNITY'
                          ? 'post_community_view'
                          : 'more_horiz'
                    )
                  "
                >
                  <span class="material-icons"> more_horiz </span>
                </button>
              </div>
            </div>
            <div class="my-2 break-words">
              <div
                *ngIf="postView.type !== 'HUDDLE'"
                [innerHtml]="postView.content"
              ></div>
              <div *ngIf="postView.type === 'REPOST'">
                <div
                  class="mt-2 flex flex-col rounded-md border p-4"
                  *ngIf="postView.refPost"
                >
                  <div class="grow-1 stretch flex items-center">
                    <p-avatar styleClass="mr-2" shape="circle">
                      <img
                        *ngIf="postView.creatorObj.profilePicUrl"
                        [src]="
                          getProfileImage(postView.creatorObj.id)
                            | image
                            | async
                        "
                        alt=""
                      />
                      <img
                        *ngIf="!postView.creatorObj.profilePicUrl"
                        src="../assets/images/user-pic-default.svg"
                        alt="default"
                      />
                    </p-avatar>
                    <div class="flex flex-col" *ngIf="postView.creatorObj">
                      <span>{{ postView.creatorObj.displayName }}</span>
                    </div>
                  </div>
                  <p [innerHTML]="postView.refPost.content"></p>
                </div>
              </div>
              <div *ngIf="postView.type === 'HUDDLE'">
                <div class="relative">
                  <a class="z-0 m-0 block min-h-0 p-0">
                    <div
                      class="relative flex w-full flex-col items-center overflow-hidden rounded-md bg-[#d4d2cf]"
                    >
                      <div
                        class="w-[calc(-243.101px + 74.8004vh)] min-w-full max-w-full"
                      >
                        <div class="relative h-0 overflow-hidden pt-[50.689%]">
                          <div class="absolute left-[0%] top-0 h-full w-full">
                            <img
                              *ngIf="!postView.posterObj.coverPhotoUrl"
                              alt=""
                              height="1127"
                              width="843"
                              class="absolute bottom-0 left-0 right-0 top-0 h-full w-full rounded-md object-cover"
                              [src]="'./assets/images/huddle-default-cover.svg'"
                            />
                            <img
                              *ngIf="postView.posterObj.coverPhotoUrl"
                              alt=""
                              height="1127"
                              width="843"
                              class="absolute bottom-0 left-0 right-0 top-0 h-full w-full rounded-md object-cover"
                              [src]="
                                getCoverPhoto(postView.posterObj.id)
                                  | image
                                  | async
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div
                  class="flex flex-col items-start justify-between pb-3 pt-2 md:flex-row md:items-center"
                >
                  <div class="flex flex-col gap-2">
                    <span
                      class="highlight-text uppercase"
                      *ngIf="postView.posterObj.startDateTime"
                      >{{
                        postView.posterObj.startDateTime
                          | date: "EEEE, MMMM d, y, h:mm a"
                      }}</span
                    >
                    <span
                      *ngIf="!postView.posterObj.startDateTime"
                      class="highlight-text uppercase"
                      >No Scheduled Date</span
                    >
                    <div class="">
                      <span class=""
                        ><strong>Activity:</strong>
                        {{ activityMap[postView.posterObj.activity] }}</span
                      >
                    </div>
                    <span class="font-semibold">{{
                      postView.posterObj.name
                    }}</span>
                    <span class="">{{
                      postView.posterObj.venue?.name
                        ? postView.posterObj.venue.name
                        : "No Location Selected"
                    }}</span>
                  </div>
                  <div class="grow-1 mt-2 flex w-full md:mt-0 md:w-auto">
                    <!-- <button
                      pButton
                      type="button"
                      [ngClass]="
                        postView.posterObj.selfHuddler?.interested
                          ? 'mp-button-filled'
                          : 'mp-button-outlined'
                      "
                      class="mp-button group flex w-full justify-center gap-2 rounded-md px-5 py-2 leading-6 transition-all md:w-auto"
                    >
                      <span class="material-icons hidden group-hover:block">
                        star
                      </span>
                      <span class="material-icons block group-hover:hidden">
                        star_outline
                      </span>
                      <span>Interested</span>
                    </button> -->
                  </div>
                </div>
              </div>

              <div *ngIf="postView.type === 'COMMUNITY'">
                <div class="relative">
                  <a class="z-0 m-0 block min-h-0 p-0">
                    <div
                      class="relative flex w-full flex-col items-center overflow-hidden rounded-md bg-[#d4d2cf]"
                    >
                      <div
                        class="w-[calc(-243.101px + 74.8004vh)] min-w-full max-w-full"
                      >
                        <div class="relative h-0 overflow-hidden pt-[50.689%]">
                          <div class="absolute left-[0%] top-0 h-full w-full">
                            <img
                              *ngIf="!postView.posterObj?.coverImageId"
                              alt=""
                              height="1127"
                              width="843"
                              class="absolute bottom-0 left-0 right-0 top-0 h-full w-full rounded-md object-cover"
                              src="../assets/images/community-default-cover.svg"
                            />
                            <img
                              *ngIf="postView.posterObj?.coverImageId"
                              alt=""
                              height="1127"
                              width="843"
                              class="absolute bottom-0 left-0 right-0 top-0 h-full w-full rounded-md object-cover"
                              [src]="
                                getCommunityCoverPhoto(postView.posterObj?.id)
                                  | image
                                  | async
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                  <div class="absolute -bottom-[30px] left-[8px]">
                    <p-avatar
                      styleClass="surface-overlay p-1 h-[5rem] w-[5rem]"
                    >
                      <img
                        *ngIf="!postView.posterObj?.logoImageId"
                        alt="default"
                        class="rounded-md"
                        src="../assets/images/community-default-logo.svg"
                      />
                      <img
                        *ngIf="postView.posterObj?.logoImageId"
                        alt="Logo"
                        class="rounded-md"
                        [src]="
                          getCommunityLogo(postView.posterObj.id)
                            | image
                            | async
                        "
                      />
                    </p-avatar>
                  </div>
                </div>
                <div>
                  <div class="relative">
                    <div class="text-center">
                      {{ postView.posterObj?.location.name }}
                    </div>
                    <div
                      class="flex flex-col items-start justify-between pb-3 pt-2 md:flex-row md:items-center"
                    >
                      <div
                        class="ml-2 flex flex-1 flex-col justify-start gap-2"
                      >
                        <!-- <div class="text-center">{{ postView.posterObj?.location.name }} </div> -->
                        <span class="h-level-2 block min-w-0 text-left">{{
                          postView.posterObj?.name
                        }}</span>
                        <!-- <span class="h-level-4 block min-w-0 text-start"
                          >{{ dummyDescription.slice(0, 60) }}
                          <span *ngIf="!showDescription">...</span>
                          <span *ngIf="showDescription">
                            {{
                              dummyDescription.slice(
                                60,
                                dummyDescription.length
                              )
                            }}
                          </span>
                          <span
                            class="primary cursor-pointer"
                            (click)="showDescription = !showDescription"
                            >{{ !showDescription ? "more" : "less" }}</span
                          >
                        </span> -->
                        <span class="h-level-4 primary"
                          >{{
                            visibilityMap[postView.posterObj?.privacy]
                          }}
                          Community</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="relative">
                <div class="flex items-center justify-between pb-3">
                  <div class="flex grow justify-end">
                    <div
                      class="relative z-0 flex shrink-0 items-stretch justify-between"
                    >
                      <div class="flex grow items-center overflow-hidden">
                        <span
                          class="sub-title cursor-pointer hover:underline"
                          (click)="onClickLike()"
                        >
                          <span>{{ postView.totalReactionCount }} likes</span>
                        </span>
                        <span class="h-level-2">&nbsp;&#8901;&nbsp;</span>
                        <span class="sub-title cursor-pointer hover:underline">
                          <span>{{ postView.totalCommentCount }} comments</span>
                        </span>
                        <!-- <span class="h-level-2">&nbsp;&#8901;&nbsp;</span>
                        <span class="sub-title hover:underline">
                          <span>{{ postView.totalShareCount }} shares</span>
                        </span>
                        <span class="h-level-2">&nbsp;&#8901;&nbsp;</span>
                        <span class="sub-title :underline">
                          <span>0 repost</span>
                        </span> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mx-3 border-t">
                  <div class="-mx-1 flex">
                    <div
                      class="shrink-1 mt-[4px] min-w-0 max-w-full flex-1 grow cursor-pointer rounded-md px-1 py-1 transition duration-300"
                    >
                      <div
                        class="mp-comment-action flex items-center justify-center gap-2"
                        (click)="onLikeUnlike(postId)"
                        [ngClass]="{ primary: postView.reacted }"
                      >
                        <span class="material-icons">{{
                          !postView.reacted ? "favorite_border" : "favorite"
                        }}</span>
                        <span class="hidden md:block">Like</span>
                      </div>
                    </div>
                    <div
                      class="shrink-1 mt-[4px] min-w-0 max-w-full flex-1 grow cursor-pointer rounded-md px-1 py-1 transition duration-300"
                    >
                      <div
                        class="mp-comment-action flex items-center justify-center gap-2"
                      >
                        <span class="material-icons">chat_bubble_outline</span>
                        <span class="hidden md:block">Comment</span>
                      </div>
                    </div>
                    <div
                      class="shrink-1 mt-[4px] min-w-0 max-w-full flex-1 grow cursor-pointer rounded-md px-1 py-1 transition duration-300"
                      [ngClass]="{
                        'pointer-events-none opacity-80': postView.refPost,
                      }"
                    >
                      <div
                        class="mp-comment-action flex items-center justify-center gap-2"
                        (click)="onMenuToggle($event, 'repost')"
                      >
                        <span class="material-icons">repeat</span>
                        <span class="hidden md:block">Repost</span>
                      </div>
                    </div>
                    <div
                      class="shrink-1 mt-[4px] min-w-0 max-w-full flex-1 grow cursor-pointer rounded-md px-1 py-1 transition duration-300"
                    >
                      <div
                        class="mp-comment-action flex items-center justify-center gap-2"
                        (click)="onMenuToggle($event, 'share')"
                      >
                        <span class="material-icons-outlined"> share </span>
                        <span class="hidden md:block">Share</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="surface-overlay mx-auto mt-5 w-full max-w-[720px]">
                  <div
                    class="mp-focus flex items-center rounded-md border p-3"
                    tabindex="1"
                  >
                    <textarea
                      placeholder="Comment"
                      (keydown.enter)="comment($event)"
                      [(ngModel)]="commentText"
                      pInputTextarea
                      rows="1"
                      maxlength="280"
                      [autoResize]="true"
                      class="w-full resize-none rounded-md px-3 py-1.5 focus:ring-0"
                    ></textarea>
                    <button
                      (click)="comment($event)"
                      pButton
                      class="mx-3 h-[32px] rounded-full p-1"
                    >
                      <span class="material-icons primary"> send </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-6 px-4 md:col-span-6">
            <div
              *ngIf="!comments.length"
              class="block flex h-40 grow flex-col items-center justify-center"
            >
              <span class="material-icons !text-6xl text-gray-400">forum</span>
              <h4 class="font-bold">No comment yet</h4>
              <span>Be the first to comment</span>
            </div>
            <p-scrollPanel
              *ngIf="comments.length"
              class="flex-1"
              [style]="{ width: '100%', height: '100%' }"
              #scrollPanel
            >
              <div
                class="search-results"
                infinite-scroll
                [infiniteScrollDistance]="1"
                [infiniteScrollUpDistance]="2"
                [infiniteScrollThrottle]="500"
                [infiniteScrollContainer]="timeLineContainer"
                [fromRoot]="true"
                (scrolled)="onScrolledDown()"
              >
                <div
                  role="row"
                  class="relative -mx-4 rounded-md py-2 transition-all duration-200 md:-mx-0"
                  *ngFor="let list of comments; let i = index"
                  (mouseover)="onMouseEvent($event)"
                  (mouseout)="onMouseEvent($event)"
                >
                  <div class="px-1">
                    <div role="gridcell">
                      <a class="min-h-0 cursor-pointer px-0 py-0">
                        <div
                          class="mx-0 my-0 flex min-h-[44px] min-w-0 items-start justify-start py-3 md:py-0"
                        >
                          <div class="mr-4 flex flex-col">
                            <p-avatar
                              shape="circle"
                              styleClass="ml-4"
                              size="large"
                            >
                              <img
                                *ngIf="list.user && list.user.profilePicUrl"
                                [src]="
                                  getProfileImage(list.user.id) | image | async
                                "
                                alt=""
                              />
                              <img
                                *ngIf="!list.user || !list.user.profilePicUrl"
                                src="../assets/images/user-pic-default.svg"
                                alt="default"
                              />
                            </p-avatar>
                          </div>
                          <div
                            class="shrink-1 grow-1 flex w-full min-w-0 flex-col items-stretch justify-between"
                          >
                            <div
                              class="surface-g-border flex flex-col rounded-lg border p-2"
                              [ngClass]="
                                isDark
                                  ? 'surface-light-200'
                                  : 'surface-light-100'
                              "
                            >
                              <span class="h-level-5 primary font-semibold">{{
                                list.user?.displayName
                              }}</span>
                              <span class="overflow-hidden text-left">{{
                                list.comment
                              }}</span>
                            </div>
                            <span class="primary h-level-6">{{
                              moment(list.commentTime).fromNow()
                            }}</span>
                          </div>
                          <div class="more-horiz flex h-[32px] flex-1">
                            <button
                              (click)="
                                onMenuToggle(
                                  $event,
                                  'more_horiz_comment',
                                  list.id
                                )
                              "
                              pButton
                              class="mx-3 h-[32px] h-full rounded-full p-1 shadow-md"
                            >
                              <span class="material-icons"> more_horiz </span>
                            </button>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </p-scrollPanel>
          </div>
        </div>
      </p-card>
    </div>
  </div>
</div>

<p-tieredMenu
  #menu
  [model]="postOptions"
  [popup]="true"
  appendTo="body"
  styleClass="p-0"
>
  <ng-template pTemplate="item" let-item>
    <a
      *ngIf="itemFor === item.item"
      (click)="onTieredMenuItemClick(item.action)"
      class="p-menuitem-link justify-content-between align-items-center flex p-3"
    >
      <div>
        <span class="ml-2"> {{ item.label }}</span>
      </div>
    </a>
  </ng-template>
</p-tieredMenu>

<app-sharelink
  *ngIf="postView"
  [event]="shareLinkEvent.asObservable()"
  objectType="POST"
  [objectId]="postId"
></app-sharelink>

<app-post-dialog
  [postDialogEvent]="postDialogEvent.asObservable()"
></app-post-dialog>

<p-dialog
  header="Select User"
  [(visible)]="userPickerVisible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [breakpoints]="{ '960px': '80vw' }"
  [draggable]="false"
  [resizable]="false"
  [appendTo]="'body'"
>
  <div #inviteUser class="relative mb-2 overflow-hidden transition-[height]">
    <app-user-picker-new
      [ref]="inviteUser"
      [showSave]="true"
      [buttonText]="'Share'"
      [events]="userPickerEvent.asObservable()"
      (submitUserPicker)="onClickInvite($event)"
      (list)="onList($event)"
      (remove)="onRemove($event)"
    ></app-user-picker-new>
  </div>
</p-dialog>

<app-dialog
  [visibleChange]="dialogEvent.asObservable()"
  [view]="'USER'"
  header="Reacted Users"
  [userDetails]="userInfo"
  width="30vw"
></app-dialog>

<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>
